import React, { useContext, useState, useEffect, useCallback } from "react"
import Countdown from "react-countdown"
import { debounce } from "lodash"
import Fuse from "fuse.js"
import {
  DrawerFinancing,
  CardOffer,
  Header,
  Footer,
  SEO,
  FinancingHeader,
  FilterSortHeader,
  HomeDesktopHeader,
  HideOnScroll,
} from "../components"
import { saveSearch } from "../api"
import { CarContext, KeyboardContext, SearchContext } from "../context"

const HERO_DATE = new Date("2020-09-30T03:59:59.000Z")

export default function IndexPage() {
  const { isKeyboardOpen } = useContext(KeyboardContext)
  const appContext = useContext(CarContext)
  const { setTerms, windowWidth, offers: unsortedOffers } = appContext
  const { modelSearch, resetSearch, activeModelSearch } = useContext(
    SearchContext
  )

  const offers = unsortedOffers?.sort((a, b) => a.position - b.position)

  const priceOrder = null
  const brandFilter = "all"
  // const [priceOrder, setPriceOrder] = useState(null)
  // const [brandFilter, setBrandFilter] = useState("all")
  const [timerActive, setTimerActive] = useState(new Date() < HERO_DATE)

  // State to control header when hide-on-scroll
  const [hideableHeaderShowed, setHideableHeaderStatus] = useState(true)

  // Save user search on DB with debounce
  const searchSaver = useCallback(
    debounce(text => text && text.length && saveSearch(text, "offers"), 1500),
    []
  )
  useEffect(() => {
    searchSaver(modelSearch)
  }, [modelSearch, searchSaver])

  // Clear user search on unmounting
  useEffect(() => resetSearch, [resetSearch])

  const fuseSearch = offers
    ? new Fuse(offers, {
        keys: ["brand", "modelName", "versionName"],
        minMatchCharLength: 2,
        threshold: 0.5,
        useExtendedSearch: true,
      })
    : null

  if (!offers) return null
  return (
    <>
      <SEO url="https://dercocenterx.cl/offers"></SEO>
      <Header />
      <div className="layout-product-list layout-product-list-offers">
        <div className={`left-bar ${isKeyboardOpen ? "smaller" : ""}`}>
          <DrawerFinancing buttonGroupOrientation="vertical" />
        </div>
        <div className="product-list">
          <HideOnScroll setState={setHideableHeaderStatus}>
            <FinancingHeader />
          </HideOnScroll>

          <FilterSortHeader
            topPosition={hideableHeaderShowed ? "second-place" : "first-place"}
            activeModelSearch={activeModelSearch}
          />

          <HomeDesktopHeader />

          {timerActive && (
            <div
              className={`countdown ${windowWidth <= 930 ? "center" : ""}`}
              data-aos="fade-down"
              data-aos-duration="500"
              data-aos-delay="250"
              data-aos-anchor-placement="center-bottom"
            >
              {windowWidth > 930 ? (
                <div className="left">
                  <div className="main">
                    OFERTAS FLASH{" "}
                    <span role="img" aria-label="Rayo">
                      ⚡
                    </span>
                  </div>
                  <div className="secondary">
                    Autos nuevos a precios de usados
                  </div>
                </div>
              ) : (
                <div className="main-mobile">
                  OFERTAS FLASH{" "}
                  <span role="img" aria-label="Rayo">
                    ⚡
                  </span>
                </div>
              )}
              <div className="timer">
                <Countdown
                  date={HERO_DATE}
                  renderer={CountdownRenderer}
                  zeroPadTime={2}
                  onComplete={() => setTimerActive(false)}
                />
              </div>
            </div>
          )}
          <div className="products">
            {buildOffersCards(
              offers.filter(
                offer => brandFilter === "all" || brandFilter === offer.brand
              ),
              priceOrder,
              fuseSearch,
              modelSearch,
              appContext,
              setTerms
            )}
          </div>
        </div>
      </div>
      <Footer shortWidth />
    </>
  )
}

const CountdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return null
  } else {
    // Render a countdown
    return (
      <>
        <div className="clock">
          <span role="img" aria-label="reloj">
            ⏳
          </span>
        </div>
        <div className="item">
          <div className="number">{days}</div>
          <div className="word">DÍAS</div>
        </div>
        <div className="item">
          <div className="number">{hours}</div>
          <div className="word">HORAS</div>
        </div>
        <div className="item">
          <div className="number">{minutes}</div>
          <div className="word">MIN</div>
        </div>
        <div className="item">
          <div className="number">{seconds}</div>
          <div className="word">SEG</div>
        </div>
      </>
    )
  }
}

const buildOffersCards = (
  offers,
  priceOrder,
  fuseSearch,
  search,
  appContext,
  terms
) => {
  const stagger = 250
  const parsedSearch = search.replace(/,/g, " | ") //Allow searching by multiple models
  const output = search
    ? fuseSearch.search(parsedSearch).map(e => e.item)
    : offers

  return output.length ? (
    (priceOrder !== null
      ? output.sort((a, b) =>
          priceOrder ? b.priceList - a.priceList : a.priceList - b.priceList
        )
      : output
    ).map((offer, i) => {
      return (
        <CardOffer
          key={i}
          offer={offer}
          appContext={appContext}
          terms={terms}
          ms={stagger * i}
        ></CardOffer>
      )
    })
  ) : (
    <div className="empty-search">Sin resultados</div>
  )
}
